import React, { Lazy, useState } from 'react';
import styled from 'styled-components';
import axios from "axios";

function UnstyledUpload(props) {
  const {app, destination, style, input_style, button_style} = props;
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('destination', destination);

      const options = {
        headers: {
          'Content-type': 'multipart/form-data', 
          'Accept': 'application/json'
        },
      };

      axios.post(`/upload/${app}`, formData, options)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <div className="upload-container" style={style}>
      <input className="upload-input" style={input_style} type="file" onChange={handleFileChange} />
      <button className="upload-button" style={button_style} onClick={handleUpload}>Upload</button>
    </div>
  );
}

const Upload = styled(UnstyledUpload)`
${props => props.cssString}
`
export default Upload;