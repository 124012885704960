import React, { Lazy } from 'react';


export default function SvgContainer(props) {
  
  return (
    <svg {...props}>
      {props.children}
    </svg>
  );
}
