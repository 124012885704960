import React, { Lazy } from 'react';
import styled from 'styled-components';

function UnstyledContainer(props) {
  return (
    <div {...props}>
      {props.children}
    </div>
  );
}

const Container = styled(UnstyledContainer)`
${props => props.cssString}
`
export default Container;