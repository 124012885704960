import React, { useRef, useState, useEffect } from "react";
import { all_components } from "../Components";
import { parseConfig } from "../Components/processes/parseConfig";

import { isArray, cloneDeep, create_UUID, deepEqual } from "../Components/tasks/utils";

import styled from 'styled-components';

const event_registry = {}

export function UnstyledRenderer(props) {
  const {
    component_id,
    component_config,
    component_state,
    component_inputs,
    component_events,
    parent_eventctl,
    cssString,
    className,
    _is_child
  } = props;
  // console.log('cssString: ', cssString, className)
 //console.log("component_config: ", cloneDeep(component_config));
 //console.log("component_state: ", cloneDeep(component_state));
 //console.log("component_inputs: ", cloneDeep(component_inputs));
 //console.log("component_events: ", cloneDeep(component_events));

  const state_original = useRef({});
  const inputs_original = useRef({});
  const events = useRef({});
  const children_events = useRef({});

  const onload_funcs = useRef({});
  const onrender_funcs = useRef({});

  const vars = useRef({
    initial: true,
  });

  if (vars.current.initial) {
    state_original.current = { ...cloneDeep(component_state) };
    console.log("renderer_states: ", cloneDeep(state_original.current), props.key, cloneDeep(component_config))
    events.current = { ...component_events };
    vars.current.id = create_UUID();
  }

  inputs_original.current = { ...cloneDeep(component_inputs) };
  // console.log("renderer_inputs: ", cloneDeep(inputs_original))

  const [renderCounter, setRenderCounter] = useState(null);
  const render_vars = useRef({
    renderCounter: renderCounter,
  });
  const increaseRenderCounter = () => {
    render_vars.current.renderCounter = render_vars.current.renderCounter + 1;
    setRenderCounter(render_vars.current.renderCounter);
  };

  const array_state_handler = {
    get: (target, key) => {
      //console.log("get array_state_handler: ", isArray(target[key]), target, key, target[key])
      if (
        typeof target[key] === "object" &&
        target[key] !== null &&
        !isArray(target[key])
      ) {
        return new Proxy(target[key], state_handler);
      }
      if (isArray(target[key])) {
        return new Proxy(target[key], array_state_handler);
      }
      // const result = target[key]
      // console.log('get-result: ', result)
      // return typeof result === 'undefined'? null : result;
      return target[key]
    },
    set: (target, prop, value) => {
      if (!deepEqual(target[prop], value)) {
       //console.log("set array_state_handler: ", target, prop, value)
        target[prop] = value;
        increaseRenderCounter();
        return true;
      }
      return true;
    },
  };

  const state_handler = {
    get: (target, key) => {
      //console.log("get state_handler: ", isArray(target[key]), target, key, target[key])
      if (
        typeof target[key] === "object" &&
        target[key] !== null &&
        !isArray(target[key])
      ) {
        //console.log("get state_handler: as object proxy")
        return new Proxy(target[key], state_handler);
      }
      if (isArray(target[key]) && target.watchList && target.watchList.includes(key)) {
        //console.log("get state_handler: as array proxy")
        return new Proxy(target[key], array_state_handler);
      }
      //console.log("get state_handler: no proxy")
      // const result = target[key]
      // console.log('get-result: ', result)
      // return typeof result === 'undefined'? null : result;
      return target[key]

    },
    set: (target, prop, value) => {
      // console.log("set state_handler: ", target, prop, value)
      if (target.watchList) {
        // console.log("set state_handler pre-watchlist: ", target, prop, value)
        if (
          target.watchList.includes(prop) &&
          !deepEqual(target[prop], value)
        ) {
          target[prop] = value;
          increaseRenderCounter();
        } else {
          target[prop] = value;
        }
      } else {
        target[prop] = value;
      }

      return true;
    },
  };

  const state = new Proxy(state_original, state_handler);

  const inputs_handler = {
    get: (target, key) => {
      if (typeof target[key] === "object" && target[key] !== null) {
        return new Proxy(target[key], inputs_handler);
      }
      return target[key];
    },
    set: (target, prop, value) => {
     //console.log("cannot set input");
      return true;
    },
  };

  const inputs = new Proxy(inputs_original, inputs_handler);


  const eventctl = {
    emit: (event, event_inputs, target) => {
      // console.log(
      //   "events ch",
      //   events.current,
      //   event,
      //   event_inputs,
      //   eventctl ? eventctl.get_id() : eventctl,
      //   emitter.get_id(),
      //   reemitter ? reemitter.get_id() : reemitter,
      //   cloneDeep(state.current)
      // );
      // console.log(event, target, typeof target);
      const results = {}
      Object.entries(event_registry).forEach(([element_id, element_events]) => {
        if( typeof target === "undefined"){
          if (element_events[event]){
            results[element_id] = element_events[event](...event_inputs);
          }
        }else{
          if(element_id.includes(target)){
            if (element_events[event]){
              results[element_id] = element_events[event](...event_inputs);
            }
          }
        }
      })
      return results
      // if (events.current[event]) {
      //   if (event_inputs && isArray(event_inputs)) {
      //     const results = events.current[event](...event_inputs);
      //     const parent_results = eventctl.passToParent(
      //       event,
      //       event_inputs,
      //       emitter,
      //       reemitter
      //     );
      //     const children_results = eventctl.passToChildren(
      //       event,
      //       event_inputs,
      //       emitter,
      //       reemitter
      //     );
      //     return [results, parent_results, children_results];
      //   } else {
      //     const results = events.current[event]();
      //     const parent_results = eventctl.passToParent(
      //       event,
      //       event_inputs,
      //       emitter,
      //       reemitter
      //     );
      //     const children_results = eventctl.passToChildren(
      //       event,
      //       event_inputs,
      //       emitter,
      //       reemitter
      //     );
      //     return [results, parent_results, children_results];
      //   }
      // } else {
      //   const parent_results = eventctl.passToParent(
      //     event,
      //     event_inputs,
      //     emitter,
      //     reemitter
      //   );
      //   const children_results = eventctl.passToChildren(
      //     event,
      //     event_inputs,
      //     emitter,
      //     reemitter
      //   );
      //   return [null, parent_results, children_results];
      // }
    },
    add_onload: (func_id, onload_func, func_inputs) => {      
      onload_funcs.current[func_id] = [onload_func, func_inputs]
    },
    remove_onload: (func_id) => {      
      delete onload_funcs.current[func_id]
    },
    add_onrender: (func_id, onrender_func, func_inputs) => {      
      onrender_funcs.current[func_id] = [onrender_func, func_inputs]
    },
    remove_onrender: (func_id) => {      
      delete onrender_funcs.current[func_id]
    },
    passToParent: (event, event_inputs, emitter, reemitter) => {
      //console.log("passToParent: ", parent_eventctl)
      if (
        parent_eventctl &&
        (typeof reemitter === "undefined" ||
          reemitter.get_id() !== parent_eventctl.get_id())
      ) {
        // console.log("emitting parent", parent_eventctl.get_id(), eventctl.get_id(), emitter.get_id(), reemitter ? reemitter.get_id() : reemitter)
        return parent_eventctl.emit(event, event_inputs, emitter, eventctl);
      }
    },
    passToChildren: (event, event_inputs, emitter, reemitter) => {
      // console.log("CHILDREN!!!!!: ",
      // cloneDeep(state.current),
      // cloneDeep(inputs.current),
      // cloneDeep(children_events.current),
      // eventctl.get_id(), emitter.get_id(), reemitter ? reemitter.get_id() : reemitter);
      const children_id = Object.keys(children_events.current);
      const children_results = [];
      children_id.forEach((child_id) => {
        if (
          emitter.get_id() !== child_id &&
          (typeof reemitter === "undefined" || reemitter.get_id() !== child_id)
        ) {
          children_results.push(
            children_events.current[child_id].emit(
              event,
              event_inputs,
              emitter,
              eventctl
            )
          );
          // console.log("emitting child", eventctl, emitter, reemitter)
        }
      });
      return children_results;
    },
    addChild: (child_eventctl) => {
      // console.log("children_events: ", cloneDeep(children_events), child_eventctl)
      children_events.current[child_eventctl.get_id()] = child_eventctl;
    },
    removeChild: (child_eventctl) => {
      // console.log("remove children_events: ", cloneDeep(children_events), child_eventctl)
      delete children_events.current[child_eventctl.get_id()]
    },
    get_id: () => {
      return vars.current.id;
    },
  };

  if (vars.current.initial) {
    if (parent_eventctl) {
      parent_eventctl.addChild(eventctl);
    }

    Object.keys(events.current).forEach((event_str) => {
      events.current[event_str] = parseConfig(
        events.current[event_str],
        state.current,
        inputs.current,
        eventctl
      );
    });

    if (typeof component_config.name === "undefined"){
      event_registry[vars.current.id] = events.current
    }else{
      event_registry[component_config.name+"-"+vars.current.id] = events.current
    }
  }

  const render_elements = (element, name) => {
    // console.log("render_elements:", element, name)
    if (element && element.children) {
      const children = [];
      element.children.forEach((child) => {
        if (child) {
          const name = child.name;
          const child_id = create_UUID();
          let renderer_name = null
          if (child && child.type === "component" && child.config) {
            // state.current[child_id] = child.state; 
            // console.log("child", child, cloneDeep(state))
            renderer_name = child.config.name ? parseConfig(cloneDeep(child.config.name),child.state,child.inputs,child.events) : name
            children.push(
              <Renderer
                key={renderer_name}
                data-twlcName={renderer_name}
                component_config={child.config}
                component_state={child.state}
                component_inputs={child.inputs}
                component_events={child.events}
                parent_eventctl={eventctl}
                cssString={child.cssString}
                className={className}
                _is_child={true}
              />
            );
          } else {
           // console.log("child", child, child.name);
            if (child.type) {
              children.push(render_elements(child, child.name));
            }
          }
        }
      });
      let elem_name = typeof name == 'string' ? name : ""
      const rendered_element = React.createElement(
        all_components[element.type],
        { ...element.props, key: name, 'data-twlcName': name, 'className': element.props.className +" "+ className+" "+ elem_name},
        children
      );
      return rendered_element;
    } else {
      if (element) {
        if (
          element.type &&
          Object.keys(all_components).includes(element.type)
        ) {
          let elem_name = typeof name == 'string' ? name : ""
          const rendered_element = React.createElement(
            all_components[element.type],
            { ...element.props, key: name, 'data-twlcName': name, 'className': element.props.className +" "+ className+" "+ elem_name }
          );
          return rendered_element;
        }
      }
    }
  };


  let parsed_config
  try {
    parsed_config = parseConfig(
    cloneDeep(component_config),
    state.current,
    inputs.current,
    eventctl
  );
  } catch ({ name, message }) {
    console.dir(error);
    parsed_config = {
      name:"error",
      type:"text",
      props:{
        text:`${name}-${message}`,
        style:{
          position: 'fixed',
          bottom: '1rem',
          left: '1rem'
        }
      }
    }
  }
  
  
  // console.log(
  //     "parsed_config",
  //     parsed_config,
  //     state.current,
  //     inputs.current,
  //     eventctl
  //   );

  if (vars.current.initial) {
    vars.current.initial = false;
  }

  useEffect(() => {
    return () => {
      if (parent_eventctl) {
        parent_eventctl.removeChild(eventctl);
      }
      delete event_registry[vars.current.id]
    };
  }, []); 

  let rendered_elements
  try {
    rendered_elements = render_elements(parsed_config, props["data-twlcName"]);
  } catch (error) {
    console.dir(error);
    rendered_elements = <p style="{'fontSize': '.5rem'}" >render failed</p>;
  }

  useEffect(() => {
    Object.values(onload_funcs.current).forEach((onload_config) => {
      onload_config[0](...onload_config[1])
    })

    return () => {};
  }, []); 

  useEffect(() => {
    Object.values(onrender_funcs.current).forEach((onrender_config) => {
      onrender_config[0](...onrender_config[1])
    })

    return () => {};
  }); 

  return rendered_elements
}

const Renderer = styled(UnstyledRenderer)`
${props => props.cssString}
`
export default Renderer;