import React, { useEffect, useState } from 'react';

function Calendar(props) {
  const [appointments, setAppointments] = useState([]);
  const { api } = props
  useEffect(() => {
    // Fetch appointments from backend
    fetch(api)
      .then(response => response.json())
      .then(data => setAppointments(data));
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Appointments</h1>
      {appointments.map(appointment => (
        <div key={appointment.id} style={styles.appointment}>
          <p style={styles.appointmentTime}>{appointment.time}</p>
          <p style={styles.appointmentDescription}>{appointment.description}</p>
        </div>
      ))}
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: 'white',
    padding: '20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  appointment: {
    borderBottom: '1px solid #eee',
    paddingBottom: '10px',
  },
  appointmentTime: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  appointmentDescription: {
    fontSize: '14px',
  },
};

export default Calendar;