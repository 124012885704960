// import { JSONEditor } from "vanilla-jsoneditor";
import { useEffect, useRef } from "react";

let JSONEditor = null;

export default function JsonEditor(props) {
  const refContainer = useRef(null);
  const refEditor = useRef(null);
  let {content, update_func, error_func, style} = props

  let editor_props = {
    content: {json:content},
    onChange: (updatedContent, previousContent, { contentErrors, patchResult }) => {
      // content is an object { json: JSONValue } | { text: string }
      if (update_func){
        update_func(updatedContent.json)
      }
      if (error_func && contentErrors){
        error_func(contentErrors)
      }
      // console.log('onChange', { updatedContent, previousContent, contentErrors, patchResult })
      content = updatedContent
    }
  }

  useEffect(() => {
    if (typeof JSONEditor === 'function') {
      // create editor
      // console.log("create editor", refContainer.current);
      refEditor.current = new JSONEditor({
        target: refContainer.current,
        props: editor_props
      });
    } else {
      JSONEditor = import("vanilla-jsoneditor").then(data=>{
        JSONEditor = data.JSONEditor
        // console.log("json-editor", data, JSONEditor)
        // create editor
        // console.log("create editor", refContainer.current);
        refEditor.current = new JSONEditor({
          target: refContainer.current,
          props: editor_props
        });
      })
    }
    return () => {
      // destroy editor
      if (refEditor.current) {
        // console.log("destroy editor");
        refEditor.current.destroy();
        refEditor.current = null;
      }
    };
  }, []);

  // update props
  useEffect(() => {
    if (refEditor.current) {
      // console.log("update props", editor_props);
      refEditor.current.updateProps(editor_props);
    }
  }, [editor_props]);

  return <div className="vanilla-jsoneditor-react" ref={refContainer} style={style}></div>;
}