import React, { Lazy } from 'react';


export default function SvgPolygon(props) {
  
  return (
    <polygon {...props}>
    </polygon>
  );
}
