import React from 'react';
import styled from 'styled-components';

const UnstyledTable = ({ data, columns }) => {

  if( typeof columns === "undefined" ){
    let columns = Object.keys(data[0])
  }

  return (
    <table>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex*2-1}>
            {columns.map((column, columnIndex) => (
              <td key={columnIndex}>
                {typeof row[column] === "object" ? String(JSON.stringify(row[column])): String(row[column])}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const Table = styled(UnstyledTable)`
${props => props.cssString}
`
export default Table;