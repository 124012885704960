import React, { Lazy, useRef, useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';

export default function Anime(props) {
  const animationRef = useRef([]);
  
  useEffect(() => {
    props.animation_configs.forEach((animation_config, i_config) => {
      const elems = document.querySelectorAll(animation_config.targets);
      animationRef.current[i_config] = anime({...animation_config, targets: elems});
    });
    return () => {
      animationRef.current.forEach((animation, i_anim) => {
        animationRef.current[i_anim].remove('targets');
        animationRef.current[i_anim].pause();
      })
     //console.log(animationRef.current);
    }
  });

  return (
    <div {...props} className="anime-component">
     {props.children}
    </div>
  ); 
}
