import React, { Lazy } from 'react';


export default function SvgRect(props) {
  
  return (
    <rect {...props}>
    </rect>
  );
}
