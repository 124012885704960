import React, { Lazy } from 'react';


export default function SvgEllipse(props) {
  
  return (
    <ellipse {...props}>
    </ellipse>
  );
}
