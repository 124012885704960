import React, { Lazy } from 'react';


export default function TextArea(props) {
  return (
    <textarea {...props}>
      {props.text}
    </textarea>
  );
}
